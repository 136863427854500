import { gsap, ScrollTrigger, TimelineMax } from "gsap/all";
import { projectData } from "./projectData";

gsap.config({
  nullTargetWarn: false,
});

let textTimeline;

gsap.registerPlugin(ScrollTrigger);
function initAnimations() {
  // directional animations
  function animateFrom(elem, direction) {
    direction = direction | 1;

    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 1.5,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }

  gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
    hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      //   markers: true,
      trigger: elem,
      onEnter: function () {
        animateFrom(elem);
      },
      onEnterBack: function () {
        animateFrom(elem, -1);
      },
      onLeave: function () {
        hide(elem);
      }, // assure that the element is hidden when scrolled into view
    });
  });

  ScrollTrigger.create({
    // markers: true,
    trigger: ".svgtext",
    onEnter: function () {
      gsap.to("#textPath", {
        duration: 1.4,
        attr: { startOffset: "8%" },
      });
    },
    // onEnterBack: function () {
    //   gsap.to("#textPath", {
    //     attr: { startOffset: "-8%" },
    //   });
    // },
  });

  document.querySelector(".svgtext--1").addEventListener("click", () => {
    const url = document.querySelector(".svgtext--1").dataset.url;
    let newWindow = window.open();
    newWindow.location.href = url;
  });

  // let prevPage = 2;
  let currentPage = projectData.length - 1;
  let nextPage = projectData.length;

  let isImgAnimating = false;
  document.querySelector(".arrow-next").addEventListener("click", () => {
    if (isImgAnimating) return;
    isImgAnimating = true;

    currentPage++;
    currentPage = currentPage % projectData.length;
    nextPage = (currentPage + 1) % projectData.length;
    // currentPage === 0
    //   ? (prevPage = projectData.length - 1)
    //   : (prevPage = (currentPage - 1) % projectData.length);
    // console.log(currentPage, nextPage);
    animateImgs();
  });
  let switch0 = 0;
  let switch1 = 1;

  function animateImgs() {
    const img0 = document.querySelector(`.masked-image-${switch0}`);
    const img1 = document.querySelector(`.masked-image-${switch1}`);
    const imgSmall0 = document.querySelector(`.masked-image-small-${switch0}`);
    const imgSmall1 = document.querySelector(`.masked-image-small-${switch1}`);

    img0.style.background = `url(${projectData[nextPage].image1}) center center / 100% no-repeat`;
    imgSmall0.style.background = `url(${projectData[nextPage].image2}) center center / 100% no-repeat`;

    setTimeout(() => {
      img1.style.background = `url(${projectData[currentPage].image1}) center center / 100% no-repeat`;
      imgSmall1.style.background = `url(${projectData[currentPage].image2}) center center / 100% no-repeat`;
      switch0 === 0 ? (switch0 = 1) : (switch0 = 0);
      switch1 === 1 ? (switch1 = 0) : (switch1 = 1);
      isImgAnimating = false;
    }, 1200);

    img0.style.opacity = 1;
    img1.style.opacity = 0;
    imgSmall0.style.opacity = 1;
    imgSmall1.style.opacity = 0;

    var tl = gsap.timeline({});
    tl.to("#textPath", { attr: { startOffset: "-100%" }, duration: 0.4 })
      .call(changeTitle)
      .to("#textPath", { opacity: 0, duration: 0 })
      .to("#textPath", { attr: { startOffset: "140%" }, duration: 0 })
      .to("#textPath", { opacity: 1, duration: 0 })
      .to("#textPath", { attr: { startOffset: "10%" }, duration: 0.4 });
    var tl2 = gsap.timeline({ delay: 0.15, ease: "elastic.out(0.9, 0.5)" });
    tl2
      .to("#textPath2", { attr: { startOffset: "-100%" }, duration: 0.4 })
      .to("#textPath2", { opacity: 0, duration: 0 })
      .to("#textPath2", { attr: { startOffset: "140%" }, duration: 0 })
      .to("#textPath2", { opacity: 1, duration: 0 })
      .to("#textPath2", { attr: { startOffset: "10%" }, duration: 0.4 });

    function changeTitle() {
      document.querySelector(".svgtext--1").dataset.url =
        projectData[nextPage].url;
      document.querySelector("#textPath").innerHTML =
        projectData[nextPage].title;
      document.querySelector("#textPath2").innerHTML =
        projectData[nextPage].subtitle;
    }
  }

  // SPLITTING
  const infoText = document.querySelectorAll(".info-text");
  const splut1 = document.querySelectorAll(".info-text-1 .char");
  const splut2 = document.querySelectorAll(".info-text-2 .char");

  textTimeline = gsap.timeline({
    repeat: -1,
    repeatDelay: 0,
    ease: "Power3.easeOut",
    // paused: true,
  });

  textTimeline
    .to(infoText, {
      opacity: 1,
    })
    .from(splut1, {
      y: "150",
      stagger: 0.04,
      opacity: 0,
    })
    .to(splut1, {
      delay: 5,
      duration: 0.5,
      opacity: 0,
    })
    .from(splut2, {
      delay: 0,
      y: "150",
      stagger: 0.04,
      opacity: 0,
    })
    .to(splut2, {
      delay: 5,
      duration: 0.5,
      opacity: 0,
    });

  // textTimeline.play(0);
}

function killAnimations() {
  // textTimeline.paused();
  if (textTimeline) textTimeline.kill();
}

// GLOOPY MENU
function initMenu() {
  // let isMenuOpen = false;
  let isMenuAnimating = false;
  // var overlay = document.querySelector(".shape-overlays");
  const paths = document.querySelectorAll(".shape-overlays__path");
  const menuLinks = document.querySelectorAll(".menu-link");

  const numPoints = 3;
  const numPaths = paths.length;
  const delayPointsMax = 0.3;
  const delayPerPath = 0.25;
  const duration = 0.9;
  let isOpened = false;
  const pointsDelay = [];
  const allPoints = [];
  // var ease = Power2.easeInOut;

  var tl = new TimelineMax({
    onUpdate: render,
    onComplete: () => {
      menuLinks.forEach((el) => el.classList.remove("transitioning"));
      isMenuAnimating = false;
    },
  });

  for (var i = 0; i < numPaths; i++) {
    var points = [];
    allPoints.push(points);
    for (var j = 0; j < numPoints; j++) {
      points.push(100);
    }
  }
  document.querySelector(".hamburger").addEventListener("click", (e) => {
    toggleMenu(e);
  });
  document.querySelector(".fluid-menu *").addEventListener("click", (e) => {
    toggleMenu(e);
  });

  const reversedLI = Array.prototype.slice
    .call(document.querySelectorAll(".fluid-menu li"))
    .reverse();

  const tlOpen = gsap.timeline({
    paused: true,
    // onComplete: () => (isMenuAnimating = false),
  });
  tlOpen
    .add(() => {
      document.querySelector(".fluid-menu ul").style.transform =
        "translateY(0)";
      menuLinks.forEach((el) => el.classList.add("transitioning"));
      isMenuAnimating = true;
    })
    .to(".fluid-menu", { opacity: 1, duration: 0 })
    .from(reversedLI, {
      delay: 1,
      opacity: 0,
      stagger: 0.1,
    })
    .to(".fluid-menu li", { pointerEvents: "all" });

  const tlClose = gsap.timeline({
    paused: true,
    // onComplete: () => (isMenuAnimating = false),
  });
  tlClose
    .add(() => (isMenuAnimating = true))
    .to(reversedLI, {
      opacity: 0,
      stagger: 0.1,
      pointerEvents: "none",
    })
    .add(() => {
      document.querySelector(".fluid-menu ul").style.transform =
        "translateY(-100vh)";
    });

  function toggleMenu() {
    // console.log("from toggleMenu");
    if (isMenuAnimating) return;
    // console.log("from toggleMenu active");
    isOpened ? tlClose.play(0) : tlOpen.play(0);
    isOpened = !isOpened;
    toggle();
  }
  document.querySelectorAll(".menu li").forEach((x) => {
    x.addEventListener("click", () => {
      // isMenuOpen = false;
    });
  });

  function toggle() {
    // console.log("from toggle");
    tl.progress(0).clear();

    for (var i = 0; i < numPoints; i++) {
      pointsDelay[i] = Math.random() * delayPointsMax;
    }

    for (var k = 0; k < numPaths; k++) {
      var points = allPoints[k];
      var pathDelay = delayPerPath * (isOpened ? k : numPaths - k - 1);

      for (var j = 0; j < numPoints; j++) {
        var config = {
          ease: "Power2.easeInOut",
        };
        config[j] = 0;
        var delay = pointsDelay[j];

        tl.to(points, duration, config, delay + pathDelay);
        // tl.to(points, duration - delay, config, delay + pathDelay);
      }
    }
  }

  function render() {
    for (var i = 0; i < numPaths; i++) {
      var path = paths[i];
      var points = allPoints[i];

      var d = "";
      d += isOpened ? `M 0 0 V ${points[0]} C` : `M 0 ${points[0]} C`;

      for (var j = 0; j < numPoints - 1; j++) {
        var p = ((j + 1) / (numPoints - 1)) * 100;
        var cp = p - ((1 / (numPoints - 1)) * 100) / 2;
        d += ` ${cp} ${points[j]} ${cp} ${points[j + 1]} ${p} ${points[j + 1]}`;
      }

      d += isOpened ? ` V 100 H 0` : ` V 0 H 0`;
      path.setAttribute("d", d);
    }
  }
}

export { initAnimations, killAnimations, initMenu };
