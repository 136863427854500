// import salaryImg from "url:./imgs/3dECC.png";
import barbie1 from "url:./imgs/richback.png";
import barbie2 from "url:./imgs/barbie2.png";
import costEarth from "url:./imgs/costEarth.png";
import costEarthStats from "url:./imgs/costEarthStats.png";
// import salary1 from "url:./imgs/salary1.png";
// import salary2 from "url:./imgs/salary2.png";
import rob1 from "url:./imgs/rob1.png";
import rob2 from "url:./imgs/rob2.png";
// import nick1 from "url:./imgs/nick1.png";
// import nick2 from "url:./imgs/nick2.png";
// import svg1 from "url:./imgs/svg1.png";
// import energy1 from "url:./imgs/energy1.png";
// import energy2 from "url:./imgs/energy2.png";

export const projectData = [
  {
    title: "CostTheEarth.com",
    subtitle: "Application",
    image1: costEarth,
    image2: costEarthStats,
    url: "https://costtheearth.netlify.app/",
  },
  {
    title: "BarbieMacLaurin.com",
    subtitle: "Website / CMS Integration",
    image1: barbie1,
    image2: barbie2,
    url: "https://barbiemaclaurin.com/",
  },
  // {
  //   title: "SalaryTimer.com",
  //   subtitle: "Application",
  //   image1: salary1,
  //   image2: salary2,
  //   url: "https://www.salarytimer.com",
  // },
  {
    title: "RobFarquhar.com",
    subtitle: "Website / CMS Integration",
    image1: rob1,
    image2: rob2,
    url: "https://robfarquhar.com",
  },
  // {
  //   title: "EnergyCost.uk",
  //   subtitle: "Application",
  //   image1: energy1,
  //   image2: energy2,
  //   url: "https://www.energycost.uk",
  // },
  // {
  //   title: "NickBaxter.net",
  //   subtitle: "Website",
  //   image1: nick1,
  //   image2: nick2,
  //   url: "https://www.nickbaxter.net",
  // },
  // {
  //   title: "SVG Map UK",
  //   subtitle: "Animation / Data Map",
  //   image1: svg1,
  //   image2: salaryImg,
  //   url: "https://svgmapuk.netlify.app/",
  // },
];
